import React from 'react';
import { Route, IndexRedirect, Redirect } from 'react-router';

import Documentation from './components/Documentation';
import DocumentationContent from './components/DocumentationContent';
import Explorer from './components/Explorer';
import Layout from './components/layout/Layout';
import NotFoundPage from './components/NotFoundPage';
import SignUp from './components/developer_pages/SignUp';
import LandingPage from './components/LandingPage/LandingPage';
import LogIn from './components/developer_pages/LogIn';
import LogOut from './components/developer_pages/LogOut';
import VerifyEmail from './components/developer_pages/VerifyEmail';
import VerifyEmailChange from './components/developer_pages/VerifyEmailChange';
import RequestPasswordReset from './components/developer_pages/RequestPasswordReset';
import ResetPassword from './components/developer_pages/ResetPassword';
import TermsAndConditions from './components/developer_pages/TermsAndConditions';
import Account from './components/developer_pages/Account';
import Profile from './components/developer_pages/Profile';
import InstanceLinkCallbackPopup from './components/developer_pages/InstanceLinkCallbackPopup';
import MyApps from './components/developer_pages/MyApps';
import ApplicationForm from './components/developer_pages/ApplicationForm';
import WebLinkSchemaDocument from './components/WebLinkSchemaDocument';
import LMSSchemaDocument from './components/LMSSchemaDocument';
import CoreSchemaDocument from './components/CoreSchemaDocument';
import LMSDeprecationTracker from './components/deprecation_tracker/LMSDeprecationTracker';
import CoreDeprecationTracker from './components/deprecation_tracker/CoreDeprecationTracker';
import WebLinkDeprecationTracker from './components/deprecation_tracker/WebLinkDeprecationTracker';

import AuthorisedRoute from './router/AuthorisedRoute';
import UnAuthorisedRoute from './router/UnAuthorisedRoute';

export const routes = (
  <Route title="Administrate | DX" path="" component={Layout}>
    <Route title="Home" path='/' component={LandingPage} />
    <Route path="docs/core" showEditor component={Explorer}>
      <IndexRedirect to="01_welcome.md" />
      <Route showArticles docsSection="core" component={Documentation}>
        <Route title="Core API" path='api' component={CoreSchemaDocument} />
        <Route title="Core API | Upcoming Changes" path="deprecation-tracker" component={CoreDeprecationTracker} />
        <Route title="Core Docs" path='*' component={DocumentationContent} />
      </Route>
    </Route>
    <Route path="docs/guides" showEditor component={Explorer}>
      <IndexRedirect to="01_intro.md" />
      <Route showArticles docsSection="guides" component={Documentation}>
        <Route title="Resources" path="*" component={DocumentationContent} />
      </Route>
    </Route>
    <Route path="docs/lms" showEditor={false} component={Explorer}>
      <IndexRedirect to="01_welcome.md" />
      <Route showArticles docsSection="lms" component={Documentation}>
        <Route title="LMS API" path="api" component={LMSSchemaDocument} />
        <Route title="LMS API | Upcoming Changes" path="deprecation-tracker" component={LMSDeprecationTracker} />
        <Route title="LMS Docs" path="*" component={DocumentationContent} />
      </Route>
    </Route>
    <Route path="docs/weblink" showEditor={false} component={Explorer}>
      <IndexRedirect to="01_welcome.md" />
      <Route showArticles docsSection="weblink" component={Documentation}>
        <Route title="WebLink API" path="api" component={WebLinkSchemaDocument} />
        <Route title="WebLink API | Upcoming Changes" path="deprecation-tracker" component={WebLinkDeprecationTracker} />
        <Route title="WebLink Docs" path="*" component={DocumentationContent} />
      </Route>
    </Route>
    <Route path="docs/legacy" showEditor={false} component={Explorer}>
      <IndexRedirect to="01_legacy_introduction.md" />
      <Route showArticles docsSection="legacy" component={Documentation}>
        <Route title="Legacy Rest API" path="*" component={DocumentationContent} />
      </Route>
    </Route>

    <Redirect from="docs/01_welcome.md" to="/" />

    {/* fallback cover any new folders not initially planned for */}
    <Route path="docs/:docsSection" showEditor={false} component={Explorer}>
      <Route showArticles component={Documentation}>
        <Route title="Docs" path='*' component={DocumentationContent} />
      </Route>
    </Route>

    <Redirect from="docs" to="docs/core" />

    <AuthorisedRoute path="account" component={Account}>
      <IndexRedirect to="profile" />
      <Route title="Profile" path="profile" component={Profile} />
      <Route title="Instance Callback" path="instance-callback" component={InstanceLinkCallbackPopup} />
      <Route title="New App" path="my-apps/new" component={ApplicationForm} />
      <Route title="New App (Verify)" path="my-apps/:key" component={ApplicationForm} />
      <Route title="My Apps" path="my-apps" component={MyApps} />
    </AuthorisedRoute>
    <UnAuthorisedRoute title="Sign Up" path="signup" component={SignUp} />
    <UnAuthorisedRoute title="Login" path="login" component={LogIn} />
    <Route title="Log Out" path="logout" component={LogOut} />
    <Route title="Verify Email "path="verify-email/:key" component={VerifyEmail} />
    <Route title="Verify Email Change" path="verify-email-change/:key" component={VerifyEmailChange} />
    <Route title="Request Password Reset" path="request-password-reset" component={RequestPasswordReset} />
    <Route title="Reset Pasword" path="reset-password/:token" component={ResetPassword} />
    <Route title="Terms & Conditions" path='terms-and-conditions' component={TermsAndConditions} />
    <Route title="Not Found" path="*" component={NotFoundPage} />
  </Route>
);
